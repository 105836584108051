import React from 'react';

interface Props extends React.ComponentProps<'label'> {
  name?: string;
  children: React.ReactNode;
}

export default function Label({ children, name, ...labelProps }: Props) {
  return (
    <label
      className="block text-darkText text-14 font-semibold mb-0.25"
      htmlFor={name}
      {...labelProps}
    >
      {children}
    </label>
  );
}
