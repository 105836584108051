import { useSignUpMutation } from 'api/auth';
import { useFetchSkillsQuery } from 'api/skills';
import camelize from 'camelize';
import AuthPage from 'components/AuthPage';
import Btn from 'components/Btn';
import Form from 'components/Form';
import Loader from 'components/Loader';
import routes from 'helpers/routes';
import useFlashMessages from 'hooks/useFlashMessages';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SignUpParams from 'types/SignUpParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';

export default function SignUp() {
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();

  const [values, setValues] = useState<SignUpParams>({
    email: '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    phone: '',
    skillIds: [],
  });
  const { data: skills, isLoading: isLoadingSkills } = useFetchSkillsQuery();

  const [signUp, { isLoading }] = useSignUpMutation();

  const [errors, setErrors] =
    useState<ValidationErrorPayload<SignUpParams> | null>(null);

  const translateErrors = useTranslateErrors();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleToggleSkill = (id: number) => {
    setValues((v) => ({
      ...v,
      skillIds: v.skillIds.includes(id)
        ? v.skillIds.filter((t) => t !== id)
        : [...v.skillIds, id],
    }));
  };

  const handleSubmit = async () => {
    const result = await signUp(values);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        window.scroll({ top: 0, behavior: 'smooth' });
        setErrors(camelize(result.error.data.errors));
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
          timeout: false,
        });
      }
    } else {
      addFlashMessage(t('fanRequestBuilder.messages.success'));
    }
  };

  return (
    <AuthPage heading={t('auth.signUp.heading')}>
      <Form onSubmit={handleSubmit}>
        <div className="flex mb-3 items-start gap-2">
          <div className="flex-1">
            <Form.TextInput
              name="firstName"
              label={t('auth.signUp.firstName')}
              onChange={handleChange}
              required
              errors={translateErrors(errors?.firstName)}
            />
          </div>

          <div className="flex-1">
            <Form.TextInput
              name="lastName"
              label={t('auth.signUp.lastName')}
              onChange={handleChange}
              required
              errors={translateErrors(errors?.lastName)}
            />
          </div>
        </div>

        <Form.TextInput
          name="email"
          label={t('auth.signUp.email')}
          onChange={handleChange}
          required
          value={values.email}
          errors={translateErrors(errors?.email)}
        />

        <Form.PhoneInput
          onChange={(phone) => setValues({ ...values, phone })}
          label={t('auth.signUp.phone')}
          required
          name="phone"
          value={values.phone}
          country="us"
          errors={translateErrors(errors?.phone)}
        />

        <Form.TextInput
          type="password"
          name="password"
          label={t('auth.signUp.password')}
          onChange={handleChange}
          required
          errors={translateErrors(errors?.password)}
        />

        <Form.TextInput
          type="password"
          name="passwordConfirmation"
          label={t('auth.signUp.passwordConfirmation')}
          onChange={handleChange}
          errors={translateErrors(errors?.passwordConfirmation)}
          required
        />

        <Form.Field
          label={t('auth.signUp.skills.label')}
          errors={
            errors?.creator?.length ? [t('Validation__ChooseOne')] : undefined
          }
        >
          {skills ? (
            skills.map(({ id, name }) => (
              <div
                key={id}
                className="mb-0.5 flex items-center gap-1 last:mb-0"
              >
                <Form.Checkbox
                  checked={values.skillIds.includes(id)}
                  onChange={() => handleToggleSkill(id)}
                  id={`skill-${id}`}
                />

                <label
                  className="text-14 text-dark bump-up-1"
                  onClick={() => handleToggleSkill(id)}
                >
                  {t(`auth.signUp.skills.options.${name}`)}
                </label>
              </div>
            ))
          ) : isLoadingSkills ? (
            <Loader />
          ) : (
            <div>
              {t(`auth.signUp.errorLoadingSkills`)}
              <span />
            </div>
          )}
        </Form.Field>

        <Btn type="submit" isLoading={isLoading} className="mb-2">
          {t('auth.signUp.submit')}
        </Btn>

        <div className="text-center mt-0.5 text-14">
          {t('auth.signUp.alreadyHaveAccount')}{' '}
          <Link
            to={routes.auth.logIn}
            className="text-socialiePink hover:text-socialiePinkHover underline inline"
          >
            {t('auth.signUp.logIn')}
          </Link>
          .
        </div>
      </Form>
    </AuthPage>
  );
}
