import { configureStore } from '@reduxjs/toolkit';
import api from 'api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import auth from './reducers/auth';
import ui from './reducers/ui';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth,
    ui,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
