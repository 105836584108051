import React, { useContext, createContext } from 'react';
import useDebouncedWindowDimensions from './useDebouncedWindowDimensions';

interface IsMobileContext {
  isMobile: boolean;
  isMobileUserAgent: boolean;
  isiOS: boolean;
  windowWidth: number;
  isAndroid: boolean;
}

// Updated to accomodate iPhone 12 Pro Max
// https://ios-resolution.com/iphone-12-pro-max/
const BREAKPOINT = 430;

export const Context = createContext<IsMobileContext>({
  isMobileUserAgent: false,
  isiOS: false,
  windowWidth: window.innerWidth,
  isMobile: window.innerWidth < BREAKPOINT,
  isAndroid: false,
});

export function IsMobileProvider({ children }: { children: React.ReactNode }) {
  const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(
    navigator.userAgent
  );
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const { width: windowWidth } = useDebouncedWindowDimensions();
  const isMobile = windowWidth < BREAKPOINT;
  return (
    <Context.Provider
      value={{ isMobile, isMobileUserAgent, isiOS, windowWidth, isAndroid }}
    >
      {children}
    </Context.Provider>
  );
}

export default function useIsMobile() {
  const { isMobile, isMobileUserAgent, isiOS, windowWidth, isAndroid } =
    useContext(Context);
  return { isMobile, isMobileUserAgent, isiOS, windowWidth, isAndroid };
}
