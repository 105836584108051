import { useFetchCurrentUserQuery } from 'api/auth';
import ErrorPage from 'components/ErrorPage';
import LoadingPage from 'components/LoadingPage';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import ErrorFallback from './components/ErrorFallback';
import FlashMessages from './components/FlashMessages';
import GlobalTopBar from './components/GlobalTopBar';
import Auth from 'screens/Auth';
import Home from 'screens/Home';

export default function Layout() {
  const { isLoading, error } = useFetchCurrentUserQuery();
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <div>
          <Helmet title="Socialie Creators" />
          <FlashMessages />
          {isLoading ? (
            <LoadingPage />
          ) : error ? (
            <ErrorPage />
          ) : currentUser ? (
            <>
              <GlobalTopBar />
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>

                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </>
          ) : (
            <Auth currentUser={currentUser} />
          )}
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
