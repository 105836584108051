import { useLogInMutation } from 'api/auth';
import { useCreateMagicLinkMutation } from 'api/magicLinks';
import isValidContactValue from 'helpers/isValidContactValue';
import routes from 'helpers/routes';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Btn from './Btn';
import Form from './Form';
import Modal from './Modal';

interface Props {
  initialValues?: { contactValue?: string; password?: string };
  renderError?: (error: string, accountExists: boolean) => React.ReactNode;
}

export default function LogInForm(props: Props) {
  const { initialValues, renderError } = props;
  const { t } = useTranslation();
  const [isMagicLinkModalOpen, setIsMagicLinkModalOpen] = useState(false);
  const [contactValue, setContactValue] = useState(
    initialValues?.contactValue || ''
  );
  const [password, setPassword] = useState(initialValues?.password || '');
  const [isInvalidContactValue, setIsInvalidContactValue] = useState(false);
  const [logIn, { data, isLoading, error }] = useLogInMutation();

  const handleSubmit = () => {
    setIsInvalidContactValue(false);
    if (isValidContactValue(contactValue)) {
      logIn({ contactValue, password });
    } else {
      setIsInvalidContactValue(true);
    }
  };

  const hasError = !!(data && 'error' in data) || !!error;
  const accountExists = !!data && 'accountExists' in data && data.accountExists;

  return (
    <>
      {hasError &&
        (renderError ? (
          renderError(
            (data && 'error' in data && data.error) || '',
            accountExists
          )
        ) : (
          <div className="notice notice--error">{t('auth.logIn.error')}</div>
        ))}
      <Form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Form.TextInput
            name="contactValue"
            value={contactValue}
            onChange={(e) => setContactValue(e.target.value)}
            label={t('auth.logIn.contactValue.label')}
            placeholder={t('auth.logIn.contactValue.placeholder')}
            errors={
              isInvalidContactValue ? t('global.invalidContactValue') : null
            }
          />

          <Form.TextInput
            name="password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t('auth.logIn.password.label')}
            placeholder={t('auth.logIn.password.placeholder')}
          />
        </div>

        <Btn
          className="mb-2"
          type="submit"
          disabled={!contactValue || !password}
          isLoading={isLoading}
        >
          {t('auth.logIn.submit')}
        </Btn>

        <Btn
          type="button"
          color="outline"
          onClick={() => setIsMagicLinkModalOpen(true)}
          className="mb-2"
        >
          {t('auth.logIn.magicLinkButton')}
        </Btn>

        <div className="text-center mt-0.5 text-14">
          {t('auth.logIn.needAnAccount')}{' '}
          <Link
            to={routes.auth.signUp}
            className="text-socialiePink hover:text-socialiePinkHover underline inline"
          >
            {t('auth.logIn.signUp')}
          </Link>
          .
        </div>
      </Form>

      {isMagicLinkModalOpen && (
        <MagicLinkModal
          onRequestClose={() => setIsMagicLinkModalOpen(false)}
          initialEmail={contactValue}
        />
      )}
    </>
  );
}

interface MagicLinkModalProps {
  initialEmail: string;
  onRequestClose: () => void;
}

function MagicLinkModal(props: MagicLinkModalProps) {
  const { initialEmail, onRequestClose } = props;
  const [email, setEmail] = useState(initialEmail);
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [createMagicLink, { isLoading }] = useCreateMagicLinkMutation();

  const handleSubmit = async () => {
    const result = await createMagicLink(email);
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('auth.logIn.magicLinkModal.success', { email }));
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      renderHeading={t('auth.logIn.magicLinkModal.heading')}
    >
      <Form onSubmit={handleSubmit}>
        <div>
          <Form.TextInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={t('auth.logIn.magicLinkModal.label')}
            placeholder={t('auth.logIn.magicLinkModal.placeholder')}
            description={t('auth.logIn.magicLinkModal.description')}
            autoFocus
          />
        </div>

        <Modal.Actions>
          <Btn type="submit" disabled={!email} isLoading={isLoading}>
            {t('global.continue')}
          </Btn>
        </Modal.Actions>
      </Form>
    </Modal>
  );
}
