import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const api = createApi({
  reducerPath: "api",
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SERVER_URL,
    credentials: "include",
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    { status: number; data: { errors: any } },
    {}
  >,
  endpoints: () => ({}),
});

export default api;
