import { useValidateMagicLinkMutation } from 'api/magicLinks';
import LoadingPage from 'components/LoadingPage';
import routes from 'helpers/routes';
import useFlashMessages from 'hooks/useFlashMessages';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

export default function MagicSignIn() {
  const token = useParams<{ token: string }>().token;
  const history = useHistory();
  const { t } = useTranslation();
  const [validateMagicLink] = useValidateMagicLinkMutation();
  const { addFlashMessage } = useFlashMessages();
  useEffect(
    () => {
      const handleValidate = async () => {
        const result = await validateMagicLink(token);
        if ('error' in result) {
          addFlashMessage(t('auth.magicSignIn.error'), {
            isError: true,
            timeout: false,
          });
          history.push(routes.auth.logIn);
        } else {
          addFlashMessage(t('auth.magicSignIn.success'));
        }
      };

      handleValidate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <LoadingPage />;
}
