import { useState } from 'react';
import DefaultPhoneInput from 'react-phone-input-2';
import Field from './Field';
import InputContainer from './InputContainer';
import 'react-phone-input-2/lib/style.css';

interface Props
  extends React.ComponentProps<typeof DefaultPhoneInput>,
    Omit<
      React.ComponentProps<typeof InputContainer>,
      'multiline' | 'onClickIcon' | 'value'
    > {
  label?: React.ReactNode;
  description?: string;
  skipField?: boolean;
  name?: string;
  required?: boolean;
}

export default function PhoneInput(props: Props) {
  const [isFocused, setIsFocused] = useState(false);

  const {
    label,
    required,
    name,
    errors,
    description,
    prefix,
    leftIcon,
    rightIcon,
    leftIconClassNames,
    rightIconClassNames,
    onClear,
    skipField,
    variant,
    value,
    ...inputProps
  } = props;

  const renderInput = () => (
    <InputContainer
      errors={errors}
      onClear={onClear}
      variant="phone"
      isFocused={isFocused}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      rightIconClassNames={rightIconClassNames}
      leftIconClassNames={leftIconClassNames}
      prefix={prefix}
      disabled={inputProps.disabled}
    >
      <DefaultPhoneInput
        {...inputProps}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        inputProps={{
          name,
          autoComplete: 'off',
          type: 'tel',
        }}
        containerClass="!h-full"
        inputClass="!border-0 !font-sans !bg-transparent !text-dark !pl-7 !w-full !outline-none !focus:outline-none !focus-visible:outline-none !disabled:opacity-50"
        buttonClass="!bg-transparent !border-0 !border-r !border-solid !border-grey6 !pr-1"
      />
    </InputContainer>
  );

  if (skipField) return renderInput();

  return (
    <Field
      label={label}
      name={name}
      errors={errors}
      description={description}
      required={required}
    >
      {renderInput()}
    </Field>
  );
}
