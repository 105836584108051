import React from 'react';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import UserMenu from './UserMenu';
import { Link } from 'react-router-dom';
import routes from 'helpers/routes';
import { IconCompanyPlaceholder, IconHome } from 'icons';

export default function GlobalTopBar() {
  const currentUser = useAppSelector(selectCurrentUser);
  if (!currentUser) return null;

  return (
    <div className="fixed top-0 left-0 right-0 z-50 px-3 py-1.5 flex items-center h-7 bg-white border-b-default">
      <div className="flex items-center">
        <Link to={routes.index} className="flex items-center group mr-1">
          <span className="flex items-center justify-center w-5 h-5 rounded-full border-default mr-1 bg-grey8 cursor-pointer">
            <div className="p-0.5 group-hover:hidden">
              <IconCompanyPlaceholder className="w-full h-full text-lightIcon" />
            </div>

            <IconHome className="w-3 h-3 text-dark hidden group-hover:block" />
          </span>

          <span className="text-dark font-bold cursor-pointer">
            {currentUser.name}
          </span>
        </Link>
      </div>

      <div className="ml-auto flex items-center">
        <UserMenu currentUser={currentUser} />
      </div>
    </div>
  );
}
