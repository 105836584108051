import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createMagicLink: builder.mutation<void, string>({
      query: (contactValue) => ({
        url: 'magic-links',
        method: 'POST',
        body: { contactValue },
      }),
    }),

    validateMagicLink: builder.mutation<FetchCurrentUserResponse, string>({
      query: (token) => ({
        url: 'magic-links/validate',
        method: 'POST',
        body: { token },
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useCreateMagicLinkMutation, useValidateMagicLinkMutation } =
  extendedApi;
