const routes = {
  auth: {
    logIn: '/log-in',
    signUp: '/sign-up',
    logInRecovery: '/log-in/recovery-code',
    magicSignIn: '/magic-sign-in/:token',
  },
  index: '/',
  invitation: '/invites/:code',
  settings: {
    root: '/settings',
  },
};

export default routes;
