import RecoveryCodeLogInResponse from 'types/RecoveryCodeLogInResponse';
import ValidateOtpRequest from 'types/ValidateOtpRequest';
import ValidateOtpResponse from 'types/ValidateOtpResponse';
import api from './index';
import camelize from 'camelize';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateOtp: builder.mutation<ValidateOtpResponse, ValidateOtpRequest>({
      query: (params) => ({
        url: 'mfa/validate-otp',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
    }),

    resendSmsCode: builder.mutation<
      { success: boolean },
      Pick<ValidateOtpRequest, 'mfaProtocol' | 'tempToken'>
    >({
      query: (params) => ({
        url: 'mfa/resend-sms-code',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
    }),

    recoveryCodeLogIn: builder.mutation<
      RecoveryCodeLogInResponse,
      { tempToken: string; recoveryCode: string }
    >({
      query: (params) => ({
        url: 'mfa/recovery-code-log-in',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const {
  useValidateOtpMutation,
  useResendSmsCodeMutation,
  useRecoveryCodeLogInMutation,
} = extendedApi;
