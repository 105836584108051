import api from './index';
import camelize from 'camelize';
import FetchCurrentUserResponse from 'types/FetchCurrentUserResponse';
import LogInResponse from 'types/LogInResponse';
import LogInRequest from 'types/LogInRequest';
import SignUpParams from 'types/SignUpParams';
import CurrentUserApiPayload from 'types/CurrentUserApiPayload';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCurrentUser: builder.query<FetchCurrentUserResponse, void>({
      query: () => ({ url: 'users/current' }),
      transformResponse: camelize,
    }),

    logIn: builder.mutation<LogInResponse, LogInRequest>({
      query: (params) => ({
        url: 'users/log-in',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelize,
    }),

    logOut: builder.mutation<void, void>({
      query: () => ({ url: 'users/log-out', method: 'POST' }),
    }),

    signUp: builder.mutation<CurrentUserApiPayload, SignUpParams>({
      query: (params) => ({
        url: 'users',
        method: 'POST',
        body: { user: params },
      }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const {
  useFetchCurrentUserQuery,
  useLogOutMutation,
  useLogInMutation,
  useSignUpMutation,
} = extendedApi;
