import useBodyClassName from 'hooks/useFlashMessages/useBodyClassName';
import logo from 'images/logo-socialie.svg';

interface Props {
  heading?: string;
  description?: React.ReactNode | string;
  notice?: React.ReactNode | string;
  children: React.ReactNode;
}

export default function AuthPage(props: Props) {
  const { heading, description, notice, children } = props;
  useBodyClassName('bg-white');

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="absolute top-0 left-0 px-1 tablet:px-2 py-1 tablet:py-3 z-50">
        <div className="p-1 bg-white rounded hidden tablet:block">
          <img src={logo} alt="Socialie Logo" className="w-15" />
        </div>
      </div>
      <div className="tablet:mb-2 desktop:mb-5 hidden tablet:block absolute bottom-0 tablet:-left-58 desktop:-left-51">
        <div className="w-72 h-72 bg-transparent border-12 border-solid border-socialiePink rounded-full" />
      </div>
      <div className="mt-14 hidden tablet:block fixed top-0 tablet:-right-36 desktop:-right-34">
        <div className="w-45 h-45 bg-socialieBlue rounded-full" />
      </div>
      <div className="overflow-y-auto no-scrollbar flex items-center justify-center">
        <div className="mx-2 max-w-58 tablet:w-58 py-3">
          <div className="mb-4">
            <div className="h2">{heading}</div>
            {!!description && <div className="mt-2">{description}</div>}
            {!!notice && <div className="mt-2">{notice}</div>}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
