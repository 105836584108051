import api from './index';
import camelize from 'camelize';
import Skill from 'types/Skill';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSkills: builder.query<Skill[], void>({
      query: () => ({ url: 'skills' }),
      transformResponse: camelize,
    }),
  }),
});

export default extendedApi;

export const { useFetchSkillsQuery } = extendedApi;
