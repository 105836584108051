import React from 'react';
import ReactDOM from 'react-dom';
import Layout from 'components/Layout';
import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css';
import 'styles/tailwind-build.css';
import 'styles/tippy-overrides.css';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import './i18n';
import { HelmetProvider } from 'react-helmet-async';
import { FlashMessagesProvider } from 'hooks/useFlashMessages';
import { IsMobileProvider } from 'hooks/useIsMobile';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <FlashMessagesProvider>
          <IsMobileProvider>
            <Layout />
          </IsMobileProvider>
        </FlashMessagesProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
