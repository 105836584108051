import logo from 'images/logo-socialie-sq.svg';

export default function Home() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-full px-2 max-w-60 text-center">
        <img src={logo} alt="Socialie Logo" className="mx-auto w-8 mb-3" />
        <div className="h1 mb-3">
          Thank you for signing up for Socialie Creator!
        </div>
        <div className="text-dark">
          We will contact you as soon as we have an assignment for you.
        </div>
      </div>
    </div>
  );
}
